// Copyright 2023 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { SxProps, TableBody, Theme } from '@mui/material';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

export interface CommitTableBodyProps {
  readonly sx?: SxProps<Theme>;
  readonly children?: ReactNode;
}

export const CommitTableBody = forwardRef(function CommitTableBody(
  { sx, children, ...props }: CommitTableBodyProps,
  ref: ForwardedRef<HTMLTableSectionElement>,
) {
  return (
    <TableBody {...props} ref={ref} sx={sx}>
      {children}
    </TableBody>
  );
});
